.underline {text-decoration: underline}
.text-gray {color: gray}
.text-red {color: #c00;}
.text-green {color: green;}
.text-center {text-align: center;}
.bold {font-weight: bold;}
.w-1\/2 {width: 50%;}
.block {display:block}
.flex {display: flex;}  
.justify-between {justify-content: space-between}
.items-center {align-items: center;}
.flex-wrap {flex-wrap: wrap;}
.inline-block {display: inline-block;}
.hidden {display: none}
.text-smaller {font-size: 0.75em;}
.max-w-5xl {max-width: 64rem;}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-0 {margin-bottom: 0;}
.mt-4 {margin-top: 1rem;}

@keyframes shake {
  from {transform: translateX(-3px)}
  to {transform: translateX(3px)}
}
.shake {
  animation: shake 0.12s 8 linear;
}

@media (min-width: 52rem) {
  .lg\:w-1\/2 {width: 50%}
  .lg\:flex {
    display: flex;
    gap: 1.5rem;
  }
  .lg\:table-cell {
    display: table-cell
  }
  .lg\:block {
    display: block;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:inline {
    display: inline
  }
  // .lg\:flex .chartWrapper {
  //   width: calc(50% - 0.75rem);
  // }
}