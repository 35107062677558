// send page TODO 
[type="date"] {
  margin: 0 1rem !important;
  font-size: 1.25rem;
}

.created_after label {
  background-color: #efefef;
  width: fit-content;
  color: grey
}
.start_date label {
  background-color: #efefef;
  width: fit-content;
  color: grey
}
.name label {
  background-color: #efefef;
  width: fit-content;
  color: grey
}
.created_after label:nth-child(1) {
  background-color: #fff;
  color: black
}
.start_date label:nth-child(2) {
  background-color: #fff;
  color: black
}
.name label:nth-child(3) {
  background-color: #fff;
  color: black
}
.sendSearch {
  padding: 0.25rem;
  border: 1px solid #666;
  border-radius: 8px;
  width: fit-content;
  box-shadow: 1px 3px 4px rgba(0,0,0,0.125);
}
.sendSearch input, .sendSearch select, .sendSearch label {
  border-width: 0 0 1.5px 0;
  border: 0;
  border-color: #eee;
  margin: 0 0.25rem;
  width: fit-content;
  font-size: 1.125rem;
  border-radius: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.sendSearch label {
  margin-right: -1px;
}
.sendSearch input {
  flex-direction: row-reverse;
  gap: .5rem;
}
.sendImg {
  font-size: 1.325rem;
  margin: 0 .5rem 0 .25rem;
}
.numSendsHeading {
  margin: 0.875rem 0 -1rem;
}
.sendNameInput {
  margin: 0 1rem;
}
.sendTemplateWrapper {
  padding: 0 1rem;
}
.sendTemplateGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1.25rem;
  gap: 0.5rem;
}

.mostUsed {
  max-width: 999px;
  gap: 10px;
}
.mostUsed > div {
  width: 45%
}

.ampClearFilter {
  margin-top: 0.75rem; 
  border: 2px solid black; 
  border-radius: 10px; 
  padding: 0.5rem; 
  font-family: sans-serif; 
  font-weight: bold;
  display: block
}
.ampSetFilter {
  color: blue;
  text-decoration: underline
}

.sends .tableWrapper {
  margin-top: 2rem;
}
.sends #promocodeList th {
  background-color: #232323;
  border: 3px solid rgb(96, 96, 96);
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: auto;
}