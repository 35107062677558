.home {
  h1 {display: none}
}

.donutWrapper {
  max-width: 30rem;
  margin: 0 auto;
}

.dispatchLogo {
  padding-right: 10px;
}
@media (min-width: 52rem) {
  .home {
    & h1 {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      padding: 1rem 0 1.5rem 0;
      margin-top: 1rem;
      background-color: $lightBlue;
      text-align: center;
      color: $white;
      font-size: 1.5rem;

      & .tooltip {
        width: fit-content;
        margin: 0 auto;
      }

      & #dailyTotal {
        font-size: 3rem;
      }
    }
  
  }
}