.pcodeInfoWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.5rem 1rem
  }
  .pcodeInfoWrapper p {
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.7);
    padding-bottom: 1rem; 
    margin-top: 2rem;
  }
  @media (min-width: 760px) {
    
    .pcodeInfoWrapper p:nth-of-type(7) {
      & b {
        font-size: 1.5rem;
      }
    }
    .pcodeInfoWrapper p:nth-of-type(6) {
      & b {
        font-size: 1.5rem;
      }
    }
    .pcodeInfoWrapper p b {
      font-size: 2rem;
    }
  }
  