.tableWrapper {
  max-width: 100%;
  overflow-x: auto;
}
// @media (min-width: 747px) {
//   .tableWrapper {
//     max-width: 92%;
//   }
// }

#promocodeList {
  width: 100%;
  border-collapse: collapse;
  --table-border-width: 2px;

  .pcodeLink {
    text-decoration: underline;
    color: #006495;
    font-weight: bold;
  }
  td, th {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
  td {
    text-align: left;
    font-family: $font1;
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;
    border-right: var(--table-border-width) solid gray;
    border-bottom: var(--table-border-width) solid gray;
  }
  tr td:first-of-type {
    border-left: 3px solid gray;
  }
  th {
    // border-bottom: dotted 2px $xtraLightGrey;
    border-bottom: var(--table-border-width) solid gray;
    // border-right: 1px solid gray;
    // padding: 15px;
    height: 90px;
    font-family: $font1;
    vertical-align: bottom;

    white-space: nowrap;
    position: relative;
  }
  .notRotated {
    position: absolute;
    top: 3.75rem;
    left: calc(50% - 3rem)
  }
  .rotated {
    transform: translate(calc(100% - var(--table-border-width) / 2), var(--table-border-width))
    rotate(315deg);
    transform-origin: 0% calc(100% - var(--table-border-width));
    width: 100%;
    position: absolute;
    bottom: 0; left: 0;
  }
  .rotated span {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: var(--table-border-width) solid gray;
    padding-right: 0.5rem;
  }
  .buttonWrapper {
    position: absolute;
    right: 5px;
    bottom: -2.5rem;
    // display: none;
  }
  tr:nth-last-child(even) {
    background-color: #f5f5f5;
  }
  .upArrow, .downArrow {
    display: none
  }
}

@media (min-width: 52rem) {
  .md\:rotated {
    transform: translate(calc(100% - var(--table-border-width) / 2), var(--table-border-width))
    rotate(315deg);
    transform-origin: 0% calc(100% - var(--table-border-width));
    width: 100%;
    position: absolute;
    bottom: 0; left: 0;
  }
  .md\:rotated span {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: var(--table-border-width) solid gray;
    padding-right: 0.5rem;
  }
  #promocodeList {
    th, td {
      font-size: 1rem;
      padding: 0.75rem;
    }
    td {padding-right: calc(1rem + 5px)}
    .upArrow, .downArrow {
      display: block;
      color: black;
      text-decoration: none;
      width: 0;
      height: 0;
      border-width: 8px;
      border-style: solid;
      border-color: transparent;
      margin-left: 0.25rem;
      transform: translateY(0.25rem)
    }
    .upArrow {
      border-bottom-color: currentColor;
      margin-bottom: 0.125rem;
    }
    .downArrow {
      border-top-color: currentColor;
    }
    .upArrow:disabled, .downArrow:disabled {
      color: gray;
    }
    
  }
}