
.log_in_form {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0,0,0,0.875);
  font-family: sans-serif;
  z-index: 9999;
  & h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  & form {
    width: fit-content;
    margin: 4rem auto; 
    background-color: #efefef;
    padding: 1px 1rem .75rem 1rem;
    font-size: 1.25rem;
    box-shadow: 0 0 2px 10px #fff;
    border: 2px solid gray;
  }
  & label {
    display: block;
    width: 90%;
    font-weight: bold;
  }
  & input {
    border: 2px solid black;
    padding: .5rem 0.25rem;
    display: block;
    width: 100%;
    font-weight: normal;
  }
  & button {
    border: 2px solid #222;
    padding: 5px 1rem;
    margin: 1.25rem auto;
    font-weight: bold;
    display: block;
    font-size: 1.5rem;
    background-color: #fff;
  }
}

@media (min-width: 900px) {
  .log_in_form form {
    padding: 1px 1.5rem 1rem 1.5rem;
    min-width: 50vw;
  }
}