@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$white: #ffffff;
$black: #000000;
$grey: #cccccc;
$darkBlue: #00304a;
$lightBlue: #00b3d9;
$pppBlue: #003b71;
$tfpBlue: #0a2140;
$orange: #eca642;

$font1: "Open Sans", sans-serif;

@mixin padtopbot($padtop, $padbot) {
  padding-top: $padtop;
  padding-bottom: $padbot;
}

%ease {
  // -webkit-transition: 0.5s ease-in-out;
  // -moz-transition: 0.5s ease-in-out;
  // -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

// no focus styles for mouse users
button:focus:not(:focus-visible) { outline: none; }

body {
  font-family: gotham;
  overflow: scroll;
  width: 100vw;
}

h1,
h2,
ul,
ol,
p,
h3 {
  font-family: $font1;
}
h1 {
  color: $darkBlue;
  font-size: 1.675rem;
  margin: 0px;
}
h2 {
  font-size: 1.25rem;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
  @extend %ease;
}

@media (min-width: 52rem) {
  h2 {
    font-size: 1.4rem;
  }
}

main {
  overflow-y: scroll;
  position: relative;
  height: 100vh;
}
//------------------'APP'--------------------

header {
  background: $darkBlue;
  background-image: linear-gradient(
    to bottom,
    rgba(250, 251, 252, 0.9),
    rgba(147, 168, 192, 0.9)
  );
  border-bottom: 2px solid $pppBlue;
  display: flex;
  font-size: 1.125rem;
  justify-content: space-around;
  align-items: center;
  left: 0px;
  position: sticky;
  top: 0px;
  padding-left: 1em;
  z-index: 7;
  @include padtopbot(10px, 10px);
  @extend %ease;
}
#headerAmount {
  color: #003b71;
  font-weight: bold;
  font-size: 1em;
  padding-top: 10px;
  @extend %ease;
  font-family: $font1;
}
.hamburger {
  border: 3px solid $tfpBlue;
}
.hamburger--open {
  background: #2e4b44;
  border: 3px solid $tfpBlue;
}
.mainContent {
  max-width: 2000px;
  margin: 0 auto;
}
@media only screen and (min-width: 52rem) {
  .grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    height: 100%;
    grid-template-areas: "col-1 col-2";
  }
  #headerAmount {
    // display: none;
    font-size: 1.5em;
    padding-top: 2px;
    text-align: center;
  }
}

// MOBILE HAMBURGER
.hamburger {
  background: $white;
  border-radius: 50%;
  border: 3px solid $lightBlue;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  margin-right: 5px;
  cursor: pointer;
  padding: 15px 10px;
  color: $black;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  &--open {
    background: black;
    color: $white;
  } 
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 20px;
    background: $black;
    border-radius: 9px;
    left: 7px;
    transition: 0.25s ease-in-out;
    transform-origin: left center;
  }
  span:nth-child(1) {top: 10px}
  span:nth-child(2) {top: 15px}
  span:nth-child(3) {top: 20px}

  &--open span {
    background-color: $white;
    left: 10px;
  }

  &--open span:nth-child(1) {
    transform: rotate(45deg);
    top: 10px;
  }

  &--open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &--open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 24px;
  }
} 

.error {
  color: #c00;
  font-weight: bold;
  font-size: 1.5rem;
}

.loading {
  display: block;
  margin: 1em auto;
  margin-top: 20%;
  width: 200px;
  height: 200px;
}
.loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  margin: 6px;
  border: 6px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  padding: 5px;
  font-size: 0.5em;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

button:disabled {
  color: lightgray;
}

.modalBG {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.modalInner {
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 1rem;
  overflow-y: scroll;
  z-index: 10;
}

.wrapper {
 padding: 1rem;
 overflow: hidden;
}

.pageHeading {
  text-align: center;
  font-size: 2rem;
  margin: -0.75rem 0 1.5rem;
  line-height: 1.25;
}
@media (min-width: 900px) {
  .wrapper {
    padding: 2rem;
  }
}

hr {
  background-color: $grey;
  height: 1px;
  border: none;
}

canvas {
  max-width: 100%
}

.chartWrapper {
  // -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.67);
  // -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.67);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.67);
  border-radius: 7px;
  margin-bottom: 2rem;
  border-bottom: solid 1rem $black;
  padding: 0 1rem 0.75rem 1rem;
  border-color: $pppBlue;
  & h2 {
    color: white;
    background-color: $pppBlue;
    text-align: center;
    padding-bottom: 5px;
    font-size: 1.25rem;
    margin-left: -1rem;
    margin-right: -1rem;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    margin-top: 0px;
  }
}

@media (min-width: 52rem) {
  .hamburger {display: none;}
  .chartWrapper {
    margin-bottom: 1.5rem;
  }
  .lg\:flex .chartWrapper {
    width: calc(50% - 0.75rem);
  }
}