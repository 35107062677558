// sidebar (nav) is hidden at mobile view
.sidebar {
  background-color:#232323;
  // background-image: 
  // url(https://paradigm.press/wp-content/themes/paradigmpress/img/bg.jpg);
  color: $white;
  font-family: $font1;
  height: 100%;
  left: 0;
  padding: 5rem 0 0 0;
  text-align: center;
  position: fixed;
  top: 0;
  transition: transform 0.5s;
  transform: translateX(-100%);
  width: 100%;  
  z-index: 5;
  &--open {
    transform: translateX(0);
    z-index: 2;
  }

  .linkWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 200px;
  }
  .imprint-logo {display: none;}
  .imprintLink {
    border-radius: 50%;
    margin: 0 0.5rem 0.5rem 0;
  }
  
  .imprintLogo:hover {
    filter: invert(1);
  }
  .allLink {
    border: 2px solid $white;
    color: $white;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: bold;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    line-height: 1.25;
  }
}

:is(.promocode, .pub) .imprintLink {
  margin-right: 1.25rem;
}

// #irisSearch {display: none}

.searchSubmitBtn {
  margin: 1.5rem auto;
  background-color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: white;
  display: block;
  border: 2px solid white;
  transition: all .2s ease-out;
}
.searchSubmitBtn:active {
  background-color: #232323;
}
.searchSubmitBtn:hover {
  box-shadow: 0 0 7px 1px rgba(255,255,255,0.75);
}
.searchInput {
  color: black;
  display: block;
  margin: 0 auto;
}

.searchButton {display: none;}
#searchCheckbox {
  position: absolute; 
  left: -100vw;
}
[value="today"] {
  display:none;
}
.searchModeButtonActive, .searchModeButtonInactive {
  width: 25%;
  border: 2px solid white;
  font-weight: bold;
  padding: 8px;
}
.searchModeButtonInactive {
  text-decoration: none;
  color: #ddd;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}
.searchModeWrapper a {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
form p {
  margin-bottom: -1rem;
}


@media (min-width: 52rem) {
  .sidebar .imprint-logo {display: initial;}
  .imprint-logo {
    margin-bottom: 1.325rem;
  }
  .searchExit {
    font-size: 1.4rem;
    font-weight: bold;
    border: 2.5px solid #666;
    border-radius: 50%;
    width: 2.25ch;
    height: 2.25ch;
    color: #111;
    background-color: #fff;
    position: absolute;
    right: -1ch;
    top: -1ch;
  }

  .historicalDateWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h2 {
      width: 100%
    }
    button {
      width: 100%;
      text-decoration: underline;
      margin-top: 1.5rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .historicalInputWrapper {
    button {
      width: 100%;
      text-decoration: underline;
      margin-top: 1.5rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .historicalSearchWrapper > div:nth-child(1) {
    padding-right: 10px
  }
  // .historicalInputWrapper {
  //   padding: 10px;
  //   border: 1px white dashed;
  // }
  .searchModeWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .searchModeButtonActive, .searchModeButtonInactive {
    width: 50%;
    border: 2px solid white;
    font-weight: bold;
    padding: 10px;
  }
  [value="today"] {
    display:initial;
  }
  .searchModeButtonInactive {
    border-color: #111;
    background-color: #000;
    border-bottom-color: white;
    text-decoration: none;
    color: #ddd;
    margin-top: 0;
  }
  .searchModeButtonActive {
    border-bottom: 0;
  }
  .searchModeWrapper a {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .searchModeWrapper > button:nth-child(3).searchModeButtonActive {
    border-right: 0;
  }
  .searchModeWrapper > button:nth-child(1).searchModeButtonActive {
    border-left: 0;
  }
  .sidebar {
    box-shadow: 7px -1px 14px -6px rgba(143, 143, 143, 1);
    transform: translateX(0);
    padding: 0.5rem 0 0 0.125rem;
    position: static;

    form, .filterHeading {display: none}

    .linkWrapper {
      flex-direction: column;
    }

    hr {
      height: 3px;
      margin: 1.5rem 0.875rem;
      border-radius: 13px;
    }

    .searchButton {
      border: 2px solid $white;
      color: $white;
      cursor: pointer;
      font-size: 1.25rem;
      font-weight: bold;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 2rem auto;
      font-size: 1.5rem;
      text-shadow: 0 0 3px white;
      display: block;
      line-height: 1.85;
      text-align: center;
    }
    #searchCheckbox:checked ~ label {
      background-color: white;
      border-color: black;
    }
    #searchCheckbox:checked ~ label svg {
      fill: black;
    }
    #searchCheckbox:checked ~ form {
      display: block;
      position: absolute;
      top: 9rem;
      left: 12vw;
      border: 3px solid white;
      box-shadow: 0 0 1.5rem 0.5rem black;
      border-radius: 8px;
      padding: 3rem 3rem 3rem 3rem;
      background-color: #333;
      min-width: 450px;
    }
  }
}

.emojiLink {
  text-decoration: none;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.emojiLink-emoji {
  font-size: 2rem;
  text-decoration: none;
  border: 2px solid transparent;
  display: block;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 50%;
  width: fit-content;
  aspect-ratio: 1;
}
.emojiLink-emoji.active {
  border-color: #fff;
}
.emojiLink-text {
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  color: white;
  letter-spacing: 1px;
  margin-top: 0.25rem;
}